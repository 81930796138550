export const prettyCategory = (category) => {
    if ( category === 'architectes' ) return ["Architecte", "Architectes"]
    if ( category === 'avocats' ) return ["Cabinet d'avocats", "Cabinets d'avocats"]
    if ( category === 'baby' ) return ["Adresse pour l'enfance", "Adresses pour l'enfance"]
    if ( category === 'digital' ) return ["Agence digitale", "Agences digitales"]
    if ( category === 'fiduciaires' ) return ["Fiduciaire", "Fiduciaires"]
    if ( category === 'funerailles' ) return ["Service funéraire", "Services funéraires"]
    if ( category === 'fit' ) return ["Fitness space", "Fitness spaces"]
    if ( category === 'green' ) return ["Green space", "Green spaces"]
    if ( category === 'immobilier' ) return ["Agence immobilière", "Agences immobilières"]
    if ( category === 'legal' ) return ["Juriste", "Juristes"]
    if ( category === 'mariages' ) return ["Prestataire mariage", "Prestataires mariage"]
    if ( category === 'seniors' ) return ["Service pour les seniors", "Services pour les seniors"]
    if ( category === 'therapie' ) return ["Thérapeute", "Thérapeutes"]
    if ( category === 'wellness' ) return ["Adresse bien-être", "Adresses bien-être"]
    return null
} 

export const listingCantons = (listing) => {
    let listingCantons = []

    if ( listing.data.locations ) {
        // list all locations for listing
        const listingLocations = listing.data.locations.map( location => location)

        // if location slug not already in list, add it
        listingLocations.forEach( location => {
            if ( location.zones ) {
                if ( ! listingCantons.includes( location.zones.slug ) ) {
                    listingCantons.push( location.zones.slug )
                }
            }
        })
    }
    // console.log("CANTONS", listingCantons )
    return listingCantons
}

export const listingsHasTags = (listing, tags) => {
    // Make an array that contains all tags in common between listing and selected tags (thanks to filter)
    let matches = Array.from(tags).filter( tag => listing.tags.includes(tag) )
    // If that array has all required tags (length), then it's a match
    if ( matches.length === tags.size ) return true
    return false
}