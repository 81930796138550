// https://github.com/angeloashmore/gatsby-source-prismic#Image-processing
import Img from "gatsby-image"

/** @jsx jsx */
import { jsx } from 'theme-ui'

import { MdCardGiftcard, MdTimer } from "react-icons/md"

const Customers = (props) => {
    const {
        promos,
        color,
    } = props

    return (
        <div sx={{ 
            p: 4, mb: 4, borderRadius: 2, bg: 'background' ,
            border: `2px solid ${color}`,
            boxShadow: 0,
        }}>
            <h2 sx={{mt: 0}}>Promotions en cours</h2>

            <div>
                {promos.map( promo => {
                    const {
                        header,
                        deadline,
                        description,
                        cta_text,
                        cta_link,
                        image
                    } = promo

                    return (
                        <div 
                            key={header}
                            sx={{
                                bg: 'muted', borderRadius: 1,
                                p: 4, mb: 1,
                                display: 'flex', justifyContent: 'flex-between', alignItems: 'center', flexWrap: ['wrap', 'nowrap'],
                            }}
                        >
                            {image && <Img fixed={image} sx= {{
                                mr: 4,
                                borderRadius: 0,
                                minWidth: '200px',
                            }} />}

                            <div sx={{
                                mt: [3, 0],
                            }}>
                                <h3 sx={{
                                    mb: 2, mt: 0, color: color,
                                }}>
                                    {header}
                                    {deadline &&
                                        <span sx={{
                                            fontSize: 0, color: 'grey',
                                            py: 1, px: 2, ml: 3, display: 'inline-block',
                                            bg: 'background', borderRadius: 1, position: "relative",
                                            top: "-3px",
                                        }}>
                                            <MdTimer sx={{mb: '-2px'}} /> Valide jusqu'au {deadline}
                                        </span>
                                    }
                                </h3>
                                <p sx={{
                                    fontSize: 1, mt: 0, mb: 1,
                                }}>
                                    {description}
                                </p>
                                <a href={cta_link} 
                                    title={`${cta_text} l'offre ${header}`} 
                                    target='blank'
                                    rel='nofollow noreferrer noopener'
                                    sx={{
                                        display: 'inline-block',
                                        fontSize: 3, px: 3, py: 2, mt: 2,
                                        color: color, border: `2px solid ${color}`, borderRadius: 0,
                                        textDecoration: 'none',
                                    }}
                                >
                                    <MdCardGiftcard sx={{mb: '-2px'}} /> {cta_text}
                                </a>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Customers